import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { ActivityList, UpdateActivitiesList } from '../models/activitiesList.model';

export const ActivitiesActions = createActionGroup({
    source: 'Activites',
    events: {
        'Load Activites': emptyProps(),
        'Load Activites Success': props<{ activities: ActivityList }>(),
        'Load Activites Failure': props<{ message: any }>(),

        'Get Employee Activites By Id': props<{ id: string }>(),
        'Load Activites By EmployeeId Success': props<{ activities: ActivityList }>(),

        'Update Activites': props<{ activities: UpdateActivitiesList }>(),
        'Update Activites Success': props<{ activities: ActivityList }>(),
        'Update Activites Failure': props<{ message: any }>(),
    },
});
